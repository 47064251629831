import React, { Fragment, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {ELANA,GeneralManager} from '../../../constant'

const UserPanel = () => {
    const url = localStorage.getItem('profileURL');
    const username = localStorage.getItem('creds') == null?"":JSON.parse(localStorage.getItem('creds')).username; 
    const user_type = localStorage.getItem('creds') == null?"":JSON.parse(localStorage.getItem('creds')).user_type; 
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url !== "null" && url !== "undefined"  ? process.env.REACT_APP_API_URL + url : man} alt="#" />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{username}</h6>
                <p>{user_type}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;